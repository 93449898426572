import useAuth from "../features/auth/hooks/use-auth";
import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import useGuardOnRegistrationSteps from "../features/core/hooks/use-guard-registration-steps";
import AppWrapper from "../layout/components/app-wrapper";
import Logo from "../features/core/components/logo";
import VerifyEmailForm from "../features/auth/components/verify-email-form";
import {Case, Default, Switch} from "../features/common/components/switch-case";
import {RegistrationSteps} from "../features/core/models/enums/registration-steps.enum";
import LogoutButton from "../features/auth/components/logout-button";
import useRegistrationSteps from "../features/auth/hooks/use-registration-steps";
import AccountDetailsForm from "../features/core/components/account-details-form";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import AdditionalAccountDetailsForm from "../features/core/components/additional-account-details-form";
import SkipStepVerification from "../features/core/components/skip-step-verification";

const VerificationUserPage = () => {
    const {currentUser} = useAuth();
    const hasCompletedIntroduction: boolean = useGuardOnRegistrationSteps(currentUser);
    const {currentStep, nextStep, isLastStep} = useRegistrationSteps();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLastStep) navigate("/")
    }, [currentStep]);

    if (hasCompletedIntroduction) {
        return <Navigate to="/" replace/>;
    }

    return (
        <AppWrapper>
            <div className='hidden md:flex flex-col justify-between w-full flex-grow overflow-y-auto p-4'>
                <div className='flex justify-between gap-3 w-full md:mb-4'>
                    <Logo type='complete' className='w-[6rem]'/>
                    <Switch value={currentStep}>
                        <Case case={[RegistrationSteps.STEP_1, RegistrationSteps.STEP_2]}><LogoutButton/></Case>
                        <Default><SkipStepVerification onClick={nextStep}/></Default>
                    </Switch>
                </div>
            </div>
            <AppContentWrapper className='max-h-full h-svh md:h-full'>
                <Switch value={currentStep}>
                    <Case case={RegistrationSteps.STEP_1}><VerifyEmailForm onSuccess={nextStep}/></Case>
                    <Case case={RegistrationSteps.STEP_2}><AccountDetailsForm onSuccess={nextStep}/></Case>
                    <Case case={RegistrationSteps.STEP_3}><AdditionalAccountDetailsForm onSuccess={nextStep}/></Case>
                </Switch>
            </AppContentWrapper>
        </AppWrapper>
    )
}

export default VerificationUserPage
