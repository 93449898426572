export enum LocalStorageKeys {
    AUTHENTICATION = 'authentication',
    REFRESH_TOKEN = 'refresh_token',
    EMAIL_RESET_PASSWORD = "email_reset_password",
    HAS_SENT_REQUEST_TO_BECOME_CC = "has_sent_request_to_become_cc",
    LAST_STEP_ONBOARDING = "last_step_onboarding",
    ID_ACTIVE_CHAT = "id_active_chat",
    PWA_IS_INSTALLED = "pwa_is_installed",
    RESEND_COUNT_DOWN_EMAIL_TOKEN = "resend_count_down_email_token",
}
