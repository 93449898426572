import {Navigate, Outlet, RouteProps} from "react-router-dom";
import React, {useEffect} from "react";
import useAuth from "../../auth/hooks/use-auth";
import useGuardOnRegistrationSteps from "../hooks/use-guard-registration-steps";

const GuardVerificationUser = (props: RouteProps) => {
    const {currentUser} = useAuth();
    const hasCompletedVerification: boolean = useGuardOnRegistrationSteps(currentUser);

    useEffect(() => {
    }, [hasCompletedVerification]);


    if (!hasCompletedVerification) {
        return <Navigate to="/verification" replace/>;
    }

    return <Outlet/>;
};

export default GuardVerificationUser;
